import Page from "../components/page.component";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { StationContext } from "../context/station.context";
import { useSelector } from "react-redux";
import { LoaderContext } from "../context/loader.context";
import moment from "moment";
import "moment/locale/mk";
import DiseaseTab from "../components/stations/tabs/disease-tab.component";
import DataTab from "../components/stations/tabs/data-tab.component";
import Alarms from "./alarms";
import AgronomTab from "../components/stations/tabs/agronom-tab.component";
import WeatherTab from "../components/stations/tabs/weather-tab.component";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const SingleStation = () => {
  const { loader } = useContext(LoaderContext);
  const { fetchSingleStation } = useContext(StationContext);

  const station = useSelector((state) => state.stations.activeStation);
  const [activeTab, setActiveTab] = useState("data");

  // Get UUID Parameter from url
  const { stationSlug } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    fetchSingleStation(stationSlug);
  }, []);

  return !loader && station ? (
    <Page title={station.cyrilicName} className={"app-container"}>
      <div className="row align-items-center">
        <div className="col-4 mb-5">
          <h1>{station?.cyrilicName}</h1>
          <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7 my-1">
            <li className="breadcrumb-item text-gray-600 pe-0">
              <Link
                to="/dashboard"
                className="text-gray-600 text-hover-primary"
              >
                {t("homepage")}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-600 pe-0">
              <Link to="/stations" className="text-gray-600 text-hover-primary">
                {t("stations")}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-500">
              {i18next.language === "mk" || i18next.language === "sr"
                ? station?.cyrilicName
                : station?.name}
            </li>
          </ul>
        </div>
        <div className="col-4 mb-5">
          <Link to={`/history/${stationSlug}`} className="btn btn-dark">
            {t("showHistoricalChart")}
          </Link>
        </div>
        <div className="col-4 mb-5 text-end">
          <p className="mb-0 fw-semibold text-gray-600">
            {t("lastMeasureData")}:
          </p>
          <p className="fw-bold fs-5 text-gray-800">
            {station?.lastData[0]
              ? moment(station?.lastData[0]?.timestamp)
                  .locale(i18next.language)
                  .calendar() +
                " " +
                t("hour")
              : "/"}
          </p>
        </div>
      </div>
      <div style={{ backgroundColor: "#fff" }}>
        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8 bg-light-secondary">
          <li className="nav-item" onClick={() => setActiveTab("data")}>
            <p
              className={`nav-link text-active-primary pb-4 pt-5 px-5 ${
                activeTab === "data" ? "active" : ""
              } `}
            >
              {t("tabLastData.title") + " " + t("tabLastData.subtitle")}
            </p>
          </li>

          {station?.diseases.length > 0 ? (
            <li className="nav-item" onClick={() => setActiveTab("diseases")}>
              <p
                className={`nav-link text-active-primary pb-4 pt-5 ${
                  activeTab === "diseases" ? "active" : ""
                } `}
              >
                {t("tabModelDisease.title") +
                  " " +
                  t("tabModelDisease.subtitle")}
              </p>
            </li>
          ) : (
            ""
          )}

          {station && station?.forecastCity && station?.forecastCity !== "" ? (
            <li className="nav-item" onClick={() => setActiveTab("forecast")}>
              <p
                className={`nav-link text-active-primary pb-4 pt-5 ${
                  activeTab === "forecast" ? " active" : ""
                } `}
              >
                {t("tabForecast.title") + " " + t("tabForecast.subtitle")}
              </p>
            </li>
          ) : (
            ""
          )}
          <li className="nav-item" onClick={() => setActiveTab("agronom")}>
            <p
              className={`nav-link text-active-primary pb-4 pt-5 ${
                activeTab === "agronom" ? "active" : ""
              } `}
            >
              {t("tabAgronom")}
            </p>
          </li>
          <li className="nav-item" onClick={() => setActiveTab("alarms")}>
            <p
              className={`nav-link text-active-primary pb-4 pt-5 ${
                activeTab === "alarms" ? "active" : ""
              } `}
            >
              {t("tabYourAlarms.title") + " " + t("tabYourAlarms.subtitle")}
            </p>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div
            className={`tab-pane ${
              activeTab === "data" ? "fade show active" : ""
            }`}
          >
            <DataTab station={station} />
          </div>

          {station?.diseases.length > 0 ? (
            <div
              className={`tab-pane ${
                activeTab === "diseases" ? "fade show active" : ""
              }`}
            >
              <DiseaseTab diseases={station?.diseases} />
            </div>
          ) : (
            ""
          )}
          {station && station?.forecastCity && station?.forecastCity !== "" ? (
            <div
              className={`tab-pane ${
                activeTab === "forecast" ? "fade show active" : ""
              }`}
            >
              <WeatherTab station={station} />
            </div>
          ) : (
            ""
          )}

          <div
            className={`tab-pane ${
              activeTab === "agronom" ? "fade show active" : ""
            }`}
          >
            <AgronomTab station={station?._id} />
          </div>

          <div
            className={`tab-pane ${
              activeTab === "alarms" ? "fade show active" : ""
            }`}
          >
            <Alarms station={station?._id} />
          </div>
        </div>
      </div>
    </Page>
  ) : (
    <div className="loader">
      <p className="text">{t("dataLoading")}</p>
    </div>
  );
};

export default SingleStation;
