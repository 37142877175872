import { useEffect, useState } from "react";
import PresureCard from "../../station-card/presure-card.component";
import RainfallCard from "../../station-card/rainfall-card.component";
import SimpleChartCard from "../../station-card/simple-chart-card.component";
import TemperatureCard from "../../station-card/temperature-card.component";
import WindCard from "../../station-card/wind-card.component";
import humidity from "../../../assets/images/icons/humidity.png";
import leaf from "../../../assets/images/icons/leaf.png";
import battery from "../../../assets/images/icons/battery.png";
import solar from "../../../assets/images/icons/solar-energy.png";
import { useTranslation } from "react-i18next";

const DataTab = ({ station }) => {
  const [stationData, setStationData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (station) {
      var lastItem = Object.values(station?.data).pop();
      var _lastItem = Object.values(lastItem).pop();
      console.log(station?.lastData[0], station?.data);

      setStationData(_lastItem);
    }
  }, [station]);

  useEffect(() => {
    console.log(stationData);
  }, [stationData]);

  return (
    <div className="row">
      <div className="col">
        <div className="card pt-4 mb-6 mb-xl-9">
          <div className="card-body pt-0 pb-5">
            <div className="row g-5">
              {stationData?.airHumidity != null ? (
                <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                  <div className="card h-lg-100">
                    <div className="card-body d-flex justify-content-between px-5 align-items-start flex-column">
                      <div className="m-0">
                        <span className="">
                          <img src={humidity} alt={"humidity"} />
                        </span>
                      </div>
                      <div className="d-flex flex-column mt-10">
                        <span className="fw-bold fs-2x text-gray-800">
                          {stationData?.airHumidity} %
                        </span>
                        <div className="m-0">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {t("airHumidity")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {stationData?.dewPoint != null ? (
                <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                  <div className="card h-lg-100">
                    <div className="card-body d-flex justify-content-between px-5 align-items-start flex-column">
                      <div className="m-0">
                        <span className="">
                          <i className="text-primary opacity-50 fs-2x fas fa-tint"></i>
                        </span>
                      </div>
                      <div className="d-flex flex-column mt-10">
                        <span className="fw-bold fs-2x text-gray-800">
                          {stationData?.dewPoint} °C
                        </span>
                        <div className="m-0">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {t("dewPoint")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {stationData?.earthHumidity1 != null ? (
                <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                  <div className="card h-lg-100">
                    <div className="card-body d-flex justify-content-between px-5 align-items-start flex-column">
                      <div className="m-0">
                        <span className="">
                          <img src={humidity} alt={"humidity"} />
                        </span>
                      </div>
                      <div className="d-flex flex-column mt-10">
                        <span className="fw-bold fs-2x text-gray-800">
                          {stationData?.earthHumidity1} %
                        </span>
                        <div className="m-0">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {t("earthHumidity")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {stationData?.leafWetness1 != null ? (
                <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                  <div className="card h-lg-100">
                    <div className="card-body d-flex justify-content-between px-5 align-items-start flex-column">
                      <div className="m-0">
                        <span className="">
                          <img src={leaf} alt={"leaf"} />
                        </span>
                      </div>
                      <div className="d-flex flex-column mt-10">
                        <span className="fw-bold fs-2x text-gray-800">
                          {stationData?.leafWetness1} %
                        </span>
                        <div className="m-0">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {t("leafWetness")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {stationData?.batteryVoltage != null ? (
                <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                  <div className="card h-lg-100">
                    <div className="card-body d-flex justify-content-between px-5 align-items-start flex-column">
                      <div className="m-0">
                        <span className="">
                          <img src={battery} alt={"battery"} />
                        </span>
                      </div>
                      <div className="d-flex flex-column mt-10">
                        <span className="fw-bold fs-2x text-gray-800">
                          {stationData?.batteryVoltage} V
                        </span>
                        <div className="m-0">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {t("battery")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {stationData?.solarRadiation != null ? (
                <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                  <div className="card h-lg-100">
                    <div className="card-body d-flex justify-content-between px-5 align-items-start flex-column">
                      <div className="m-0">
                        <span className="">
                          <img src={solar} alt={"solar"} />
                        </span>
                      </div>
                      <div className="d-flex flex-column mt-10">
                        <span className="fw-bold fs-2x text-gray-800">
                          {stationData?.solarRadiation} <small>W/m2</small>
                        </span>
                        <div className="m-0">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {t("solarRadiation")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row g-5">
              {stationData?.airTemperature != null ? (
                <div className="col-12 col-md-6 col-lg-4">
                  <TemperatureCard
                    data={Object.values(station?.data).pop()}
                    current={stationData?.airTemperature}
                  />
                </div>
              ) : (
                ""
              )}

              {stationData?.earthTemperature1 != null ? (
                <div className="col-12 col-md-6 col-lg-4">
                  <SimpleChartCard
                    title={t("earthTemperature")}
                    data={`${stationData?.earthTemperature1}°C`}
                  />
                </div>
              ) : (
                ""
              )}

              {stationData?.precipitation != null ? (
                <div className="col-md-6 col-lg-4">
                  <RainfallCard />
                </div>
              ) : (
                ""
              )}

              {stationData?.airPressure != null ? (
                <div className="col-md-6 col-lg-4">
                  <PresureCard presure={stationData?.airPressure} />
                </div>
              ) : (
                ""
              )}

              {stationData?.windSpeed != null ? (
                <div className="col-md-12 col-lg-4">
                  <WindCard
                    windSpeed={stationData?.windSpeed}
                    windDirection={stationData?.windDirection}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataTab;
