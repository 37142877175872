import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TemperatureCard = ({ current, data }) => {
  const { t } = useTranslation();
  const [temp, setTemp] = useState({
    minTemp: 0,
    maxTemp: 0,
    curTemp: 0,
    percentage: 0,
  });

  useEffect(() => {
    if (data.length) {
      var minTemp = null;
      var maxTemp = null;

      data.map((item) => {
        if (maxTemp === null || maxTemp < item.airTemperature)
          maxTemp = item.airTemperature;

        if (minTemp === undefined || minTemp > item.airTemperature)
          minTemp = item.airTemperature;
      });

      setTemp({
        minTemp: minTemp,
        maxTemp: maxTemp,
        curTemp: current,
        percentage: (current / maxTemp) * 100,
      });
    }
  }, [data]);

  return (
    <div className="card h-100">
      <div className="card-body d-flex flex-column">
        <div className="mb-4">
          <div className="d-flex align-items-center mb-2">
            <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1">
              {temp.curTemp + "°C"}
            </span>
          </div>
          <span className="fs-6 fw-semibold text-gray-600">
            {t("airTemperature")}
          </span>
        </div>
        <div className="d-flex align-items-center flex-column mt-3 w-100">
          <div className="d-flex justify-content-between w-100 mt-auto mb-2">
            <span className="fw-bolder fs-6 text-dark">
              {t("currently")} {temp.curTemp}
            </span>
            <span className="fw-bold fs-6 text-gray-400">{temp.maxTemp}</span>
          </div>
          <div className="h-8px mx-3 w-100 bg-light-warning rounded">
            <div
              className="bg-warning rounded h-8px"
              role="progressbar"
              style={{ width: temp.percentage.toFixed(0) + "%" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemperatureCard;
